
// This list also determines the sort order of displayed models
export const AiModelDisplayNames = Object.freeze({
  // all: "All",
  chatgpt: "ChatGPT",
  bingai: "Copilot",
  bard: "Gemini",
  sge: "AI Overview",
});


export const sidebarItems = Object.freeze([
  {
    label: "Home",
    icon: "entity-analysis",
    route: "/entity-analysis",
    suOnly: true,
  },
  {
    label: "Track Over Time",
    icon: "entity-tracker",
    route: "/entity-tracker",
    suOnly: true,
  },
  {
    label: "Compare Models",
    icon: "models-compare",
    route: "/models-compare",
    suOnly: true,
  },
  {
    label: "Compare Peers",
    icon: "peers-compare",
    route: "/peers-compare",
    suOnly: true,
  },
  {
    label: "Manage",
    icon: "ai-management-saas",
    route: "/promptGroups",
    suOnly: true,
  },
  {
    label: "Reports",
    icon: "peers-compare",
    route: "/aiReports",
    suOnly: true,
  },  
]);


export const ROUTE = Object.freeze({
  promptGroups: '/promptGroups',
  aiReports: '/aiReports',
  entityAnalysis: '/entity-analysis',
  entityTracker: '/entity-tracker',
  modelsCompare: '/models-compare',
  peersCompare: '/peers-compare',
  tagDrilldown: '/tag-drilldown',
  login: "/login",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
});

export const maxPeers = 10;
export const maxPrompts = 5;


