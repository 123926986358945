<template>
  <div>
    <div class="form-group" v-if="!editMode">

      <div class="prompts-header form-field">
        <div class="icon-title">
          <span class="icons prompt-group-prompts" />
          <label>Prompt Set</label>
        </div>
        <span class="description">What are we asking AI?</span>

        <div class="btn-group mt-2" data-toggle="buttons">
          <div v-for="(aiTemplateGroup, index) in templateGroups" :key="index">
            <input type="radio" class="btn-check" autocomplete="off" />
            <label class="btn btn-outline-primary"
                   :class="{ active: isActive(aiTemplateGroup) }"
                   @click="updateAiTemplateGroupSelection(aiTemplateGroup)">
              {{ aiTemplateGroup.ai_prompt_template_group }}
            </label>
          </div>
        </div>
      </div>

    </div>

    <div class="prompt-set">

      <div v-for="(aiPrompt, index) in aiPrompts" :key="index" class="ai-prompt"
           :class="{ 'editing-prompt': this.editingPromptIndex == index }">
        <label :for="'prompt-' + index" v-html="promptDisplay(aiPrompt)" />
        <div @click="toggleEditPrompt(index)" class="icons"
             :class="this.editingPromptIndex == index ? 'bi bi-x-circle' : 'pencil'"></div>
        <div @click="removeAiPrompt(index)" class="icons trash"></div>
      </div>
    </div>
    <div class="add-custom">
      <div class="form-field">
        <label>
          {{ isEditingPrompt ? 'Edit' : 'Add a' }} prompt
          <i class="bi bi-info-circle add-prompt-info"
             :class="{ 'show': showHelpInfo }"
             @click="showHelpInfo = true"
             v-click-outside="hideHelpInfo"></i>
        </label>
        <input
               type="text"
               class="form-control"
               v-model="aiPrompt"
               :disabled="!canAddPrompts"
               @keydown.enter="isEditingPrompt ? acceptEditedPrompt() : addAiPrompt()" />
      </div>
      <form-button class="btn-sm"
                   @click="isEditingPrompt ? acceptEditedPrompt() : addAiPrompt()"
                   :disabled="!aiPrompt.trim() || !canAddPrompts">
        {{ isEditingPrompt ? 'Accept' : 'Add' }}
      </form-button>
    </div>
    <span class="description" v-if="!canAddPrompts">Limited to {{maxPrompts}} prompts. To add a new one, first
      remove a prompt from the list.</span>

  </div>
</template>

<script>
import FormButton from "@/components/form-elements/FormButton.vue";
import vClickOutside from "click-outside-vue3";


export default {
  emits: [
    "addAiPrompt",
    "removeAiPrompt",
    "setAiPrompt",
    "updateAiTemplateGroupSelection",
    "toggleEditPrompt",
    "acceptEditedPrompt",
  ],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    aiPromptProp: String,
    aiPrompts: Array,
    editMode: Boolean,
    templateGroups: Array,
    aiPromptTemplateGroupSelection: Object,
    selectedAiPromptGroup: String,
    editingPromptIndex: Number,
  },
  data() {
    return {
      showHelpInfo: false,
    };
  },
  components: {
    FormButton,
  },
  computed: {
    maxPrompts: {
      get() {
        return this.$store.state.ai.maxPrompts;
      },
    },
    aiPrompt: {
      get() {
        return this.aiPromptProp;
      },
      set(value) {
        this.$emit("setAiPrompt", value);
      },
    },
    isEditingPrompt() {
      return this.editingPromptIndex >= 0;
    },
    canAddPrompts() {
      return this.aiPrompts?.length < this.maxPrompts || this.isEditingPrompt;
    },
  },
  methods: {
    hideHelpInfo() {
      this.showHelpInfo = false;
    },
    updateAiTemplateGroupSelection(aiTemplateGroup) {
      this.$emit("updateAiTemplateGroupSelection", aiTemplateGroup);
    },
    addAiPrompt() {
      this.$emit("addAiPrompt");
    },
    acceptEditedPrompt() {
      this.$emit("acceptEditedPrompt");
    },
    removeAiPrompt(index) {
      this.$emit("removeAiPrompt", index);
    },
    toggleEditPrompt(index) {
      this.$emit("toggleEditPrompt", index);
    },
    isActive(aiTemplateGroup) {
      return this.aiPromptTemplateGroupSelection.ai_prompt_template_group_id === aiTemplateGroup.ai_prompt_template_group_id;
    },
    promptDisplay(promptObj) {
      let prompt = promptObj.ai_prompt;
      // show tags as the entity name
      prompt = prompt.replace(new RegExp(String.raw`<${this.aiPromptTemplateGroupSelection.tag}>`, "gi"), '__ENTITY__');
      // highlight the typed entity name. We don't actually change it yet because the entity may change.
      // This is just to show the user what it will look like when saving
      if (this.selectedAiPromptGroup) {
        prompt = prompt.replace(new RegExp(String.raw`\b${this.selectedAiPromptGroup}\b`, "gi"), '__ENTITY__');
      }

      const display = '<entity-name>' + (this.selectedAiPromptGroup || 'entity') + '</entity-name>';
      return prompt.replace(/__ENTITY__/g, display);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/dialog-prompt-group-model.scss";

.add-prompt-info {
  position: relative;
  cursor: pointer;

  &.show:after {
    content: 'Type ## to include the entity name inside the prompt. When the prompt is used for peers of this entity, the entity name will be replaced with the name of the peer.';
    display: block;
    position: absolute;
    width: 23rem;
    top: 1rem;
    left: 1rem;
    border: solid 1px black;
    box-shadow: 0px 0px 15px var(--popup-shadow-color);
    padding: 2px;
    color: white;
    background-color: black;
    font-style: normal;
    z-index: calc(var(--z-popup-overlay) + var(--z-raise-more));
  }
}

.prompts-header {
  display: flex;

  .btn-group {
    gap: .5rem;
    flex-wrap: wrap;

    label.btn {
      max-width: 9rem;
      padding-left: 1rem;
      padding-right: 1rem;
      height: 36px;
      border-radius: 40px;
      @include body3;

      &:hover,
      &.active {
        background-color: var(--primary-blue5);
        color: var(--white);
      }

      &.active {
        box-shadow: 1px 1px 3px var(--grey9);
        border-color: white;
      }
    }
  }
}

.prompts {
  display: flex;
  flex-direction: column;

  .add-custom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: .5rem;

    .form-field {
      flex-grow: 1;
    }
  }
}

.prompt-set {
  margin-top: 1rem;
  margin-bottom: 1rem;

  .icons {
    cursor: pointer;
    transition: filter .3s ease;
    height: 18px;
    width: 18px;

    &:before {
      vertical-align: text-top;
    }

    &:not(:hover) {
      filter: var(--icon-fade-filter);
    }
  }
}

.ai-prompt {
  display: flex;
  padding: 0.5rem;
  align-items: center;
  gap: 1rem;
  align-self: stretch;

  &:not(:last-child) {
    border-bottom: 1px solid var(--grey4);
  }

  label {
    flex: 1 0 0;
    color: var(--grey8);
    @include body3;
  }

  &.editing-prompt label {
    color: var(--grey7);
    font-style: italic;
  }
}
</style>

<style lang="scss">
entity-name {
  border: solid 1px var(--primary-blue4);
  border-top-color: var(--primary-blue2);
  border-left-color: var(--primary-blue2);
  background: var(--primary-blue2);
  padding: 0 2px 1px;
  color: var(--primary-blue7);
}
</style>
