<template>
  <view-wrapper>
    <div class="entity-analysis">
      <!-- upper section -->
      <div class="upper-section">
        <div class="d-flex flex-column">
          <div class="dropdown-container prompt-group-dropdown">
            <div class="dropdown d-flex align-items-center">
              <span class="button-text">What does AI think about</span>
              <client-dropdown :shouldShowPeers="true" />
            </div>
          </div>
        </div>
      </div>

      <!-- lower section -->
      <div class="lower-section">
        <div class="summary-view">
          <div class="engine-option-buttons">
            <button v-for="button in filteredAiEngineModels" :key="button.ai_engine_model_id" class="btn p-2 m-1"
              :class="{ selected: selectedAiEngineModel?.model_type === button.model_type }"
              :disabled="!latestDateSummariesFor(button)?.length" @click="selectAiEngineModel(button)">
              <div class="d-flex ms-1 me-1">
                <div class="icon-wrapper">
                  <span :class="'icons ' + button.model_type"></span>
                </div>
                <span class="ms-1 m-auto">{{ getModelName(button.model_type) }}</span>
              </div>
            </button>
          </div>

          <div class="scroll-container" v-if="!hasData">
            There is no current data available
          </div>
          <div v-else class="scroll-container">
            <div v-for="(summary, index) in latestDateSummaries" :key="summary.bullet"
              class="summary-container flex-column">
              <div class="fa-sm pt-2 pb-2 mb-4">
                <div class="d-flex flex-column">
                  <Tags :response="summary" :routeback="route.entityAnalysis" class="mb-1" @tagHover="handleTagHover"
                    :shouldHighlightOnHover="true" />
                  <span :ref="'bullet_' + index" v-html="generateHighlightedBullet(summary)"></span>
                </div>
              </div>
              <sources :prompts="promptsByEngine" />
            </div>
          </div>
        </div>

        <div class="tag-section">
          <div class="date-range-section">
            <date-selector :show-label="false" />
          </div>

          <!-- top tags -->
          <div v-if="topTags.length" class="top-tags mt-4 d-flex flex-column">
            <span class="tag-section-sub-title">Top Sources Provided by {{
                getModelName(selectedAiEngineModel?.model_type) }}</span>
            <top-sources class="mt-2" :top-sources="topSources" />
          </div>

          <!-- top tags -->
          <div v-if="topTags.length" class="top-tags mt-4 d-flex flex-column">
            <span class="tag-section-sub-title">What are the top tags for {{ aiPromptGroup?.display_name }}</span>
            <top-tags class="mt-2" :top-tags="topTagsSlice" :routeback="route.entityAnalysis" :summaries="summaries" />
          </div>
        </div>
      </div>
    </div>
  </view-wrapper>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import { ROUTE, AiModelDisplayNames } from "@/constants";
import ViewWrapper from "@/views/ViewWrapper.vue";
import TopTags from "@/components/entity-analysis/TopTags.vue";
import TopSources from "@/components/entity-analysis/TopSources.vue";
import Tags from "@/components/form-elements/tags/index.vue";
import ClientDropdown from "@/components/form-elements/ClientDropdown.vue";
import DateSelector from "@/components/form-elements/DateSelector.vue";
import Sources from "@/components/form-elements/Sources.vue";
import aiUtil from "@/utils/aiUtil.js";

export default {
  components: {
    ViewWrapper,
    TopTags,
    TopSources,
    Tags,
    ClientDropdown,
    DateSelector,
    Sources
  },

  data() {
    return {
      route: ROUTE,
      summaries: {
        list: [],
        byDate: {},
      },
      chartDataForTag: null,
      prompts: [],
      preSelectedTime: [null, null],
      highlightTag: null,
    };
  },
  methods: {
    ...mapActions("general", ["setView"]),
    ...mapActions("ai", [
      "setAiTag",
      "setAiEngineModel",
      "setAiPromptGroup",
      "initializeAI",
      "setAppropriatePromptGroup",
    ]),
    handleTagHover(tag) {
      this.highlightTag = tag;
    },
    highlightTagInBullet(bullet, tag) {
      if (!bullet || !tag) return bullet;

      return bullet.replace(
        new RegExp(tag?.phrase, "gi"),
        `<span class="highlighted-tag">$&</span>`
      );
    },
    generateHighlightedBullet(summary) {
      if (!summary || !summary.bullet) return '';

      let bullet = summary.bullet;
      const sentiments = summary.sentiments;

      if (this.highlightTag) {
        bullet = this.highlightTagInBullet(bullet, this.highlightTag);
      }

      if (!sentiments) return bullet;

      sentiments.forEach(phrase => {
        if (phrase && phrase.phrase) {
          bullet = bullet.replace(
            new RegExp(phrase.phrase, "gi"),
            `<span class="${aiUtil.getSentiment(phrase.score, this.neutralThreshold)}-text">$&</span>`
          );
        }
      });
      return bullet;
    },

    getModelName(modelType) {
      return AiModelDisplayNames[modelType];
    },

    changePreSelectedTime(value) {
      this.preSelectedTime = value;
    },

    async handleTagButtonClick(tag) {
      await this.setAiTag(tag);
      this.$router.push({ path: this.route.tagDrilldown + this.route.entityAnalysis });
    },

    async fetchSummaries(promptGroupId) {
      const result = await this.$api.mongoGetSummariesByPromptGroup(
        promptGroupId,
        this.preSelectedTime
      );

      if (result && result.data) {
        const filteredSummaries = result.data.filter(
          (summary) => summary?.tags && summary?.tags.length > 0
        );

        const tagFrequencyByDate = this.formatAndCountTagsByDate(filteredSummaries);

        this.summaries = {
          list: filteredSummaries,
          byDate: tagFrequencyByDate,
        };

        return result;
      } else {
        throw new Error("Did not receive data");
      }
    },

    setDefaultAiEngineModel(summaries) {
      if (this.aiEngineModels.length > 0) {
        const uniqueModelTypesSet = new Set(summaries.map((summary) => summary.model_type));
        const uniqueModelTypesArray = Array.from(uniqueModelTypesSet);

        // Check if 'chatgpt' exists in uniqueModelTypesArray
        const chatgptIndex = uniqueModelTypesArray.indexOf("chatgpt");

        // Use 'chatgpt' as the firstModelType if found, otherwise use the first item
        const firstModelType = chatgptIndex !== -1 ? "chatgpt" : uniqueModelTypesArray[0];

        // Find the corresponding record in this.aiEngineModels
        const matchedRecord = this.aiEngineModels.find(
          (model) => model.model_type === firstModelType
        );

        // Use the found record to setAiEngineModel
        this.setAiEngineModel(matchedRecord);
      }
    },
    async fetchAndFilterSummaries() {
      try {
        let result = null;

        if (this.aiPromptGroup?.id) {
          result = await this.fetchSummaries(this.aiPromptGroup?.id);
        }

        const filteredSummaries =
          result?.data?.filter((summary) => summary.tags && summary.tags.length > 0) || [];

        const tagFrequencyByDate = this.formatAndCountTagsByDate(filteredSummaries);

        this.summaries = {
          list: filteredSummaries,
          byDate: tagFrequencyByDate,
        };

        return result;
      } catch (err) {
        (this.summaries = {
          list: [],
          byDate: {},
        }),
          console.log(err);
        throw err;
      }
    },

    fetchPrompts() {
      if (!this.aiPromptGroup?.id) {
        return Promise.resolve();
      }

      return this.$api
        .mongoGetResponsesByPromptGroup(this.aiPromptGroup.id, this.preSelectedTime)
        .then((result) => {
          if (result && result.data) {
            this.prompts = result.data;
            return result;
          } else {
            throw new Error("Did not receive prompts");
          }
        })
        .catch((err) => {
          throw err;
        });
    },

    selectAiEngineModel(selectedAiEngineModel) {
      this.selectedAiEngineModel = selectedAiEngineModel;
    },
    calculateTagFrequencies(summaries) {
      // Filter summaries based on the selected model type
      const filteredSummaries = summaries.list.filter(
        (summary) => summary.model_type === this.selectedAiEngineModel.model_type
      );

      const tagFrequency = {};
      filteredSummaries.forEach((summary) => {
        summary.tags.forEach((tag) => {
          const tagString = tag.tag;
          tagFrequency[tagString] = (tagFrequency[tagString] || 0) + 1;
        });
      });

      // Sort tags based on frequency
      const sortedTags = Object.keys(tagFrequency).sort(
        (a, b) => tagFrequency[b] - tagFrequency[a]
      );

      // Create an array of tag objects with tag and frequency
      const topTags = sortedTags.map((tag) => ({
        tag: tag,
        frequency: tagFrequency[tag],
        ai_prompt_template_tag_id: filteredSummaries
          .flatMap((summary) => summary.tags)
          .find((summaryTag) => summaryTag.tag === tag)?.ai_prompt_template_tag_id,
      }));
      return topTags;
    },
    formatAndCountTagsByDate(summaries) {
      const tagFrequencyByDate = {};

      summaries.forEach((summary) => {
        const date = summary.datetime.split("T")[0];
        if (!tagFrequencyByDate[date]) {
          tagFrequencyByDate[date] = {};
        }

        if (summary.model_type === this.selectedAiEngineModel.model_type) {
          summary.tags.forEach((tag) => {
            const tagString = tag.tag;
            tagFrequencyByDate[date][tagString] = (tagFrequencyByDate[date][tagString] || 0) + 1;
          });
        }
      });

      return tagFrequencyByDate;
    },
    findLatestDate(dates) {
      return dates.sort().reverse()[0];
    },
    isPromptGroupIdInSummaries(ai_prompt_group_id) {
      if (this.summaries && this.summaries.list && this.summaries.list.length > 0) {
        return this.summaries.list[0].ai_prompt_group_id === ai_prompt_group_id;
      }
      return false;
    },

    async fetchDataIfNeeded() {
      if (!this.aiPromptGroup?.id) {
        this.summaries = {
          list: [],
          byDate: {},
        };
        return;
      }

      if (!this.isPromptGroupIdInSummaries(this.aiPromptGroup.id)) {
        this.fetchAndFilterSummaries().then(() => {
          this.fetchPrompts();
          this.setDefaultAiEngineModel(this.summaries?.list);
        });
      }
    },

    latestDateSummariesFor(engineModel) {
      if (this.summaries.list.length === 0) {
        return [];
      }

      const dateTime = this.$dateTime.fromJSDate(new Date(this.selectedDate));
      const formattedDate = dateTime.toFormat("yyyy-MM-dd");
      const selectedDate = formattedDate || this.findLatestDate(Object.keys(this.summaries.byDate));

      // Filter summaries
      const filteredSummaries = this.summaries.list
        .filter((summary) => {
          const date = summary.datetime.split("T")[0];
          return (
            date === selectedDate && summary.model_type === engineModel.model_type
          );
        });

      return filteredSummaries.slice(0, 1);
    },
  },
  created() {
    this.fetchPrompts();
    this.preSelectedTime = [this.selectedDate, this.selectedDate];
  },
  watch: {
    aiPromptGroup() {
      this.fetchDataIfNeeded();
    },
    selectedDate(currentValue) {
      this.preSelectedTime = [currentValue, currentValue];
      this.fetchAndFilterSummaries().then(() => {
        this.fetchPrompts();
      });
    },

    aiPromptGroups(currentValue) {
      if (currentValue?.length) {
        if (!this.aiPromptGroup?.id) {
          this.setAiPromptGroup(currentValue[0]);
        }
      }
    },
  },
  computed: {
    ...mapGetters("ai", {
      aiPromptGroup: "aiPromptGroup",
      aiPromptGroups: "aiPromptGroups",
      aiEngineModels: "aiEngineModels",
      selectedDate: "selectedDate",
      neutralThreshold: "neutralThreshold",
    }),
    hasData() {
      return this.uniqueModelTypes.includes(this.selectedAiEngineModel.model_type);
    },
    promptGroupsWithPeers() {
      return this.aiPromptGroups;
    },
    topTagsSlice() {
      // Return only the top 10 tags
      return this.topTags.slice(0, 10);
    },
    tagFrequencies() {
      return this.topTagsSlice.map((tag) => ({
        number: tag.frequency,
      }));
    },
    promptsByEngine() {
      return this.prompts.filter((prompt) => prompt.model_type === this.selectedAiEngineModel.model_type);
    },
    topSources() {
      // Extract domain arrays from prompts and flatten them into a single list
      const domains = this.promptsByEngine.flatMap((prompt) => prompt.domain);

      // Remove null values from the domains list
      const filteredDomains = domains.filter((domain) => domain !== null);

      // Count occurrences of each domain
      const domainCounts = {};
      filteredDomains.forEach((domain) => {
        domainCounts[domain] = (domainCounts[domain] || 0) + 1;
      });

      // Convert domainCounts object to an array of objects with domain and count properties
      const uniqueDomains = Object.keys(domainCounts).map((domain) => ({
        domain: domain,
        count: domainCounts[domain],
      }));

      // Sort uniqueDomains array by count in descending order
      uniqueDomains.sort((a, b) => b.count - a.count);

      return uniqueDomains;
    },

    filteredAiEngineModels() {
      const models = this.aiEngineModels.filter((button) => button.model_type !== "all");
      const modelArr = Object.keys(AiModelDisplayNames);
      return models.sort((a, b) => modelArr.indexOf(a.model_type) - modelArr.indexOf(b.model_type));
    },
    topTags() {
      return this.summaries.length === 0 ? [] : this.calculateTagFrequencies(this.summaries);
    },
    selectedAiEngineModel: {
      get() {
        return this.$store.state.ai.aiEngineModel;
      },
      set(value) {
        this.setAiEngineModel(value);
      },
    },
    uniqueModelTypes() {
      // Use Set to get unique model types
      const uniqueModelTypesSet = new Set(this.summaries.list.map((item) => item.model_type));
      // Convert Set back to an array
      return [...uniqueModelTypesSet];
    },

    latestDateSummaries() {
      return this.latestDateSummariesFor(this.selectedAiEngineModel);
    },
  },
  mounted() {
    /*
     ** init ref variables
     */
    this.setView("entityAnalysis");
    this.initializeAI({ notifier: this.$notifier }).then(this.setAppropriatePromptGroup);

    this.fetchAndFilterSummaries().then(() => {
      this.fetchPrompts();
    });

    // Check if engine model is selected.
    if (!this.selectedAiEngineModel && this.summaries?.list) {
      this.setDefaultAiEngineModel(this.summaries.list);
    } else {
      this.fetchDataIfNeeded();
    }

  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_icons.scss";
@import "@/scss/_mixins.scss";

.entity-analysis {

  .upper-section {
    display: flex;
    margin: var(--component-spacing-v) 0 var(--component-spacing-v) var(--gutter-width);
    align-items: center;
    gap: 1.25rem;

    .prompt-group-dropdown {
      .dropdown {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        @include title-styles;
        font-size: 32px;
      }

      .button-text {
        color: var(--grey8);
        @include h1-style;
      }

      .dropdown-menu {
        border-radius: 20px;
      }
    }
  }

  .lower-section {
    display: flex;
    margin: 0 var(--component-spacing-v) var(--component-spacing-v) var(--gutter-width);
    align-items: flex-start;
    gap: var(--component-spacing-h);

    .scroll-container {
      position: relative;

      .select-icon {
        position: absolute;
        right: 8px;
        cursor: pointer;
      }

      .frequency {
        display: flex;
        padding: 8px 0px;
        align-items: center;
        gap: 8px;
        color: var(--primary-blue5);
        text-decoration-line: underline;
        cursor: pointer;
      }
    }

    .engine-option-buttons {
      display: flex;

      .btn {
        display: flex;
        padding: 6px 15px 6px 6px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 40px;
        background: var(--grey1);

        @include body3;
        font-weight: 500;

        min-width: 60px;

        &:disabled {
          background-color: #ccc;
          border-color: #ccc;
          color: white;
        }

        .icon-wrapper {
          background-color: white;
          border-radius: 10px;
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .btn:hover,
      .btn.selected {
        background-color: var(--primary-blue5);
        color: var(--white);
        @include body3;
        font-weight: 500;
      }
    }

    :deep(.date-range) {
      width: 140px;
    }

    .summary-view {
      display: flex;
      max-width: 42rem;
      padding: var(--component-spacing-h-generous);
      flex-direction: column;
      align-items: flex-start;
      gap: 1.5rem;

      border-radius: 1.25rem;
      border: 1px solid var(--grey2);
      background: var(--white);

      .summary-container {
        display: flex;
        width: 100%;
        padding: 0px 16px;
        justify-content: space-between;
        border-radius: 10px;
        border: 1px solid var(--grey3);
        background: var(--white);
        margin-bottom: 5px;

        :deep(.negative-text) {
          background-color: #ffa5a5;
        }

        :deep(.highlighted-tag) {
          background-color: yellow !important;
          transition: background-color 0.3s ease;
        }
      }

      .sources-title {
        color: var(--grey7);
        margin: 1rem 0;
        @include h4-style;
      }

    }

    .tag-section {
      display: flex;
      padding: var(--component-spacing-h);
      flex-direction: column;
      border-radius: 1.25rem;
      border: 1px solid var(--grey2);
      background: var(--white);

      .separator-line {
        border-top: 1px solid #dadada;
        margin-top: 10px;
      }

      .date-range-section {
        display: flex;
        justify-content: space-between;

        .tag-section-title {
          color: var(--grey9);
          @include h2-style;
          margin: auto 0;
        }
      }

      .tag-section-sub-title {
        color: var(--grey9);
        @include h3-style;
      }
    }

  }
}
</style>
