<template>
  <view-wrapper>
    <div class="prompt-groups container">
      <dialog-delete-modal @confirm="confirmDeleteAIReport" @setShowFor="showDeleteDialogFor = $event"
        :show-for="showDeleteDialogFor" />

      <div class="header-section">
        <div>
          <h3>Reports</h3>
          <span class="m-1 subtitle">View and manage generated reports</span>
        </div>
        <div v-if="filteredAiReports.length > 0" class="search-bar">
          <input type="text" v-model="searchQuery" placeholder="Search by topic" />
        </div>
      </div>

      <div v-if="filteredAiReports.length > 0" class="table-wrapper">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" @click="sortBy('ai_prompt_group_display_name')">
                <div class="d-flex flex-row">
                  <span>Topic</span>
                  <span class="sort-icon">{{ sortConfig.ai_prompt_group_display_name === 'asc' ? '↑' : '↓' }}</span>
                </div>
              </th>
              <th scope="col" @click="sortBy('ai_report_lookup_name')">
                <div class="d-flex flex-row">
                  <span>Report</span>
                  <span class="sort-icon">{{ sortConfig.ai_report_lookup_name === 'asc' ? '↑' : '↓' }}</span>
                </div>
              </th>
              <th scope="col" @click="sortBy('model_type')">
                <div class="d-flex flex-row">
                  <span>AI Engine</span>
                  <span class="sort-icon">{{ sortConfig.model_type === 'asc' ? '↑' : '↓' }}</span>
                </div>
              </th>
              <th scope="col" @click="sortBy('report_start_date')">
                <div class="d-flex flex-row">
                  <span>Date Range</span>
                  <span class="sort-icon">{{ sortConfig.report_start_date === 'asc' ? '↑' : '↓' }}</span>
                </div>
              </th>
              <th scope="col" @click="sortBy('creation_date')">
                <div class="d-flex flex-row">
                  <span>Date Generated</span>
                  <span class="sort-icon">{{ sortConfig.creation_date === 'asc' ? '↑' : '↓' }}</span>
                </div>
              </th>
              <th scope="col" @click="sortBy('status')">
                <div class="d-flex flex-row">
                  <span>Status</span>
                  <span class="sort-icon">{{ sortConfig.status === 'asc' ? '↑' : '↓' }}</span>
                </div>
              </th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(aiReport, index) in filteredAiReports" :key="index">
              <th scope="row">
                <span>{{ aiReport.ai_prompt_group_display_name }}</span>
              </th>
              <th scope="row">
                <span>{{ aiReport.ai_report_lookup_name }}</span>
              </th>
              <th scope="row">
                <div class="model-type d-flex">
                  <span :class="'icons ' + aiReport.model_type" />
                  <span class="ms-1">{{ getModelName(aiReport.model_type) }}</span>
                </div>
              </th>
              <th scope="row">
                <span>
                  {{ formatCreationDate(aiReport.report_start_date).date }}
                  {{ formatCreationDate(aiReport.report_start_date).year !==
          formatCreationDate(aiReport.report_end_date).year ?
          formatCreationDate(aiReport.report_start_date).year : '' }}
                  -
                  {{ formatCreationDate(aiReport.report_end_date).date }}
                  {{ formatCreationDate(aiReport.report_end_date).year }}
                </span>
              </th>
              <th scope="row">
                <div class="d-flex flex-row">
                  <span>{{ formatCreationDate(aiReport.creation_date).date }}</span>
                  <span class="ms-2">{{ formatCreationDate(aiReport.creation_date).time }}</span>
                </div>
              </th>
              <th scope="row">
                <span :class="getStatusClass(aiReport.status)">
                  {{ getStatusDisplay(aiReport.status) }}
                </span>
              </th>
              <th scope="row">
                <div class="d-flex actions">
                  <span v-if="aiReport.status === 'STATE_COMPLETED'" class="icons eye-small"
                    @click="showView(aiReport)" />
                  <span v-if="aiReport.status === 'STATE_COMPLETED'" class="icons trash"
                    @click="showDelete(aiReport)" />
                  <!-- <span v-if="aiReport.status === 'STATE_COMPLETED'" class="icons pdf" @click="downloadPDF(aiReport)"
                    title="download as pdf">
                    <i class="fas fa-file-pdf"></i>
                  </span> -->
                </div>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="filteredAiReports.length === 0" class="welcome-message">
        <div>Welcome to Reports Page.</div>
      </div>

      <dialog-report-modal v-if="showReportDialog" :aiReport="currentAIReport" @close="showReportDialog = false" />
    </div>
  </view-wrapper>
</template>






<script>
import { mapActions, mapGetters } from "vuex";
import useRouteHelper from "@/composables/useRouteHelper";
import ViewWrapper from "@/views/ViewWrapper.vue";
import DialogDeleteModal from "@/components/modals/DialogDeleteModal.vue";
import DialogReportModal from "@/components/modals/DialogReportModal.vue";
import pdfUtils from '@/utils/pdfUtils';
import { AiModelDisplayNames } from "@/constants";

import { ROUTE } from "@/constants";

export default {
  components: {
    ViewWrapper,
    DialogDeleteModal,
    DialogReportModal
  },
  data() {
    return {
      route: ROUTE,
      showDeleteDialogFor: "",
      currentAIReport: {},
      showReportDialog: false,
      sortConfig: {
        ai_prompt_group_display_name: 'asc',
        ai_report_lookup_name: 'asc',
        model_type: 'asc',
        report_start_date: 'asc',
        creation_date: 'asc',
        status: 'asc',
      },
      sortKey: '',
      searchQuery: '',
    };
  },



  methods: {
    ...mapActions("ai", ["initializeAI", "fetchAIReportStatus"]),
    ...mapActions("general", [
      "setShowProgress",
      "setInProgress",
      "setView",
    ]),

    getStatusDisplay(status) {
      const statusMap = {
        STATE_CREATED: 'Waiting',
        STATE_START: 'Started',
        STATE_COMPLETED: 'Completed',
        STATE_FAILED: 'Failed',
      };
      return statusMap[status] || status;
    },

    getStatusClass(status) {
      const classMap = {
        STATE_COMPLETED: 'status-completed',
        STATE_FAILED: 'status-failed',
        // Optionally, add more classes for other statuses if needed
      };
      return classMap[status] || '';
    },

    confirmDeleteAIReport() {
      if (this.currentAIReport?.ai_prompt_group_display_name === undefined) {
        // TODO is this necessary?
        return;
      }

      this.$api.deleteAIReport(this.currentAIReport.ai_client_report_id)
        .then((res) => {
          this.setShowProgress(true);
          if (!res?.data) {
            Promise.reject("Delete report failed");
          }
          this.$notifier.info({ message: "Report deleted" });
        })
        .then(() => {
          // Refresh the AI reports after deletion
          this.fetchAIReportStatus({ clientId: this.userInfo.client.clientid, notifier: this.$notifier });
          this.setShowProgress(false);
        })
        .catch((e) => {
          this.$notifier.error({ message: "Delete report failed", e });
          this.setInProgress(false);
        });
    },

    showDelete(aiReport) {
      this.currentAIReport = aiReport;
      this.showDeleteDialogFor = `Report ${aiReport.ai_report_lookup_name} of Topic ${aiReport.ai_prompt_group_display_name}`;
    },

    showView(aiReport) {
      this.currentAIReport = aiReport;
      this.showReportDialog = true;
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${month}/${day}/${year}`;
    },

    formatCreationDate(dateString) {
      const date = new Date(dateString);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';
      const formattedHours = hours % 12 || 12; // Convert 24-hour format to 12-hour format
      const formattedMinutes = String(minutes).padStart(2, '0');
      const month = date.toLocaleString('default', { month: 'short' });
      const day = date.getDate();
      const year = date.getFullYear();

      return {
        date: `${month} ${day}`,
        year: year,
        time: `${formattedHours}:${formattedMinutes} ${ampm}`
      };
    },

    async downloadPDF(aiReport) {
      await pdfUtils.downloadPDF(this.$api, aiReport);
    },
    getModelName(modelType) {
      return AiModelDisplayNames[modelType];
    },

    sortBy(key) {
      this.sortKey = key;

      if (this.sortConfig[key] === 'asc') {
        this.sortConfig[key] = 'desc';
      } else {
        this.sortConfig[key] = 'asc';
      }
    },
  },
  computed: {
    ...mapGetters("general", {
      showProgress: "showProgress",
    }),
    ...mapGetters("ai", {
      userInfo: "userInfo",
      aiReports: "aiReports",
    }),
    filteredAiReports() {
      let reports = this.aiReports.slice();
      if (this.searchQuery) {
        reports = reports.filter(report =>
          report.ai_prompt_group_display_name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
      if (this.sortKey) {
        reports.sort((a, b) => {
          let modifier = 1;
          if (this.sortConfig[this.sortKey] === 'desc') modifier = -1;
          if (a[this.sortKey].toLowerCase() < b[this.sortKey].toLowerCase()) return -1 * modifier;
          if (a[this.sortKey].toLowerCase() > b[this.sortKey].toLowerCase()) return 1 * modifier;
          return 0;
        });
      }
      return reports;
    },
  },
  watch: {
    userInfo(currentValue) {
      if (currentValue.client && currentValue.client.clientid) {
        this.fetchAIReportStatus({ clientId: currentValue.client.clientid, notifier: this.$notifier });
      }
    },
  },
  setup() {
    const { routeTo } = useRouteHelper();
    return {
      routeTo,
    };
  },
  mounted() {
    /*
     ** init ref variables
     */
    this.setView("aiReports");
    this.initializeAI({ notifier: this.$notifier });

    // Fetch AI report status when the component is mounted
    if (this.userInfo.client && this.userInfo.client.clientid) {
      this.fetchAIReportStatus({ clientId: this.userInfo.client.clientid, notifier: this.$notifier });
    }

  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/icons.scss";

.search-bar {
  margin-top: 1rem;

  input {
    padding: 0.5rem;
    border: 1px solid var(--grey3);
    border-radius: 20px;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    color: var(--grey7);

    &:focus {
      border-color: var(--primary-blue7);
      outline: none;
    }
  }
}

.prompt-groups {
  --transition-style: .3s ease;
  max-width: calc(97vw - var(--side-menu-width) - var(--gutter-width));

  margin: 2rem 0 0 var(--gutter-width);

  .header-section {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;

    h3 {
      color: var(--grey8);
      @include h1-style;
      margin: auto 0;
    }

    .subtitle {
      @include title-styles;
      font-size: 14px;
      color: var(--grey8);
    }
  }

  .welcome-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }

  /* Add styles for status classes */
  .status-completed {
    color: green;
    font-weight: 500;
    /* Optional: make it bold */
  }

  .status-failed {
    color: red;
    font-weight: 500;
    /* Optional: make it bold */
  }
}

.prompts {
  position: relative;
  cursor: pointer;

  &:hover .open-prompt-handler:after {
    border-color: var(--icon-blue);
  }

  .prompts-wrapper {
    .prompt {
      padding-bottom: .3rem;
    }
  }

  .more-prompts-indicator {
    margin-top: -1rem;
  }

  .open-prompt-handler {
    border: solid 1px var(--grey3);
    border-radius: 50%;
    padding: .5rem;
    position: absolute;
    top: 8px;
    right: 0;

    &:after {
      border-color: var(--dark-gray);
    }
  }

}

.right-buttons {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-right: 1rem;

  .top-info-message {
    color: var(--grey7);
    font-family: Inter;
    font-size: .875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-right: 3rem;
  }
}

.table-wrapper {
  padding: 1.5rem;
  border: solid 1px var(--grey3);
  border-radius: 20px;
  background-color: white;
  width: 100%;
  /* Ensure the table wrapper takes the full width of its container */

  .sort-icon {
    cursor: pointer;
    margin-left: .20rem;
  }

  .actions {
    justify-content: space-around;

    .icons {
      cursor: pointer;
      margin-right: 10px;
    }

    .pdf {
      font-size: 19px;
      color: red;
    }
  }

  table {
    width: 100%;

    thead {
      tr {
        @include body3;

        th {
          color: var(--grey7);
          font-weight: normal;
          border-color: black;
          padding: 1rem;
        }
      }
    }

    tbody {
      tr {
        color: var(--grey7);
        @include body3;

        th {
          color: var(--grey9);
          font-family: 'Open Sans';
          font-weight: 500;
          padding: 1rem;
        }

        .model-type {
          color: var(--primary-blue7);
          align-items: center;
        }
      }
    }
  }
}
</style>
