<template>
  <div>
    <view-options :field-labels="true">
      <date-range />
      <ai-models-dropdown />
    </view-options>

    <!-- lower section -->
    <response-wrapper :grouped-responses="groupedResponses" :no-data="noData" :loading-data="loadingData"
      :routeback="route.entityTracker" />
  </div>
</template>

<script>
import ResponseWrapper from "@/components/page-elements/ResponseWrapper.vue";
import AiModelsDropdown from "@/components/form-elements/AiModelsDropdown.vue";
import DateRange from "@/components/form-elements/DateRange.vue";
import ViewOptions from "@/components/page-elements/ViewOptions.vue";
import { ROUTE } from "@/constants";

export default {
  props: ["groupedResponses", "noData", "loadingData"],
  data() {
    return {
      route: ROUTE,
    };
  },
  components: {
    ResponseWrapper,
    DateRange,
    AiModelsDropdown,
    ViewOptions,
  },
}
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/icons.scss";


</style>
