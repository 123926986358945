<template>
  <div class="dialog-overlay">
    <div class="dialog-content">
      <button class="close-button" @click="$emit('close')">X</button>
      <iframe class="iframe" ref="iframe"></iframe>
      <div class="button-group">
        <!-- <button class="save-button" @click="downloadPDF">Download PDF</button> -->
        <!-- <button class="save-word-button" @click="saveAsWord">Save to Word</button> -->
        <!-- <button class="email-button" @click="showEmailModal">Email Report</button> -->
      </div>
    </div>
  </div>

  <!-- Email Modal Component -->
  <!-- <DialogEmailModal ref="emailModal" @confirmEmail="handleEmailConfirmation" /> -->
</template>

<script>
//import DialogEmailModal from "@/components/modals/DialogEmailModal.vue";
import pdfUtils from '@/utils/pdfUtils';

export default {
  components: {
  //  DialogEmailModal,
  },
  props: {
    aiReport: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      iframeContent: '',
    };
  },
  mounted() {
    this.loadReport();
  },
  methods: {
    async loadReport() {
      try {
        const response = await this.$api.getS3BucketFile(this.aiReport.report_path_html);
        this.iframeContent = response.data;
        this.setIframeContent();
      } catch (error) {
        console.error('Error loading report:', error);
        alert('Failed to load report. Please try again.');
      }
    },
    setIframeContent() {
      const iframe = this.$refs.iframe;
      if (iframe) {
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

        // Inject Font Awesome CSS link into the iframe's head
        const link = iframeDocument.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css';
        iframeDocument.head.appendChild(link);

        // Write the content to the iframe
        iframeDocument.open();
        iframeDocument.write(this.iframeContent);
        iframeDocument.close();
      }
    },
    async downloadPDF() {
      await pdfUtils.downloadPDF(this.$api, this.aiReport);
    },    

    // showEmailModal() {
    //   this.$refs.emailModal.showModal();
    // },
    // async handleEmailConfirmation(email) {
    //   try {
    //     await this.$api.sendEmailWithAttachment(email, this.aiReport.report_path_html);
    //     this.$notifier.info({
    //       message: 'Report emailed to ' + email,
    //       duration: 5000,
    //     });
    //   } catch (error) {
    //     console.error('Error sending email:', error);
    //     alert('Failed to send email. Please try again.');
    //   }
    // },

  }
};
</script>

<style scoped>
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.iframe {
  width: 100%;
  height: 700px;
  border: none;
  flex-grow: 1;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.save-button,
.email-button,
.save-word-button {
  padding: 8px 10px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.save-button {
  background-color: #007bff;
  /* Blue color for the Save as PDF button */
}

.save-button:hover {
  background-color: #0056b3;
  /* Darker blue on hover */
}

.email-button {
  background-color: #28a745;
  /* Green color for the Email Report button */
}

.email-button:hover {
  background-color: #218838;
  /* Darker green on hover */
}

.save-word-button {
  background-color: #6c757d;
  /* Gray color for the Save to Word button */
}

.save-word-button:hover {
  background-color: #565e64;
  /* Darker gray on hover */
}
</style>
