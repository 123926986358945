<template>
  <summary-wrapper :groupedSummaries="groupedSummaries" :groupedResponses="groupedResponses"
    :routeback="route.entityTracker" :loading="loadingData" :isTimeline="true">

    <div class="d-flex">

      <view-options :field-labels="true">
        <date-range />
        <ai-models-dropdown />

        <template #button>
          <form-button v-if="!selectedPromptGroupIsPeer()" class="generate-report" @click="generateReport()"
            :disabled="!groupedResponses || groupedResponses.length === 0">
            <span v-if="!isGenerating">Generate Report</span>
            <span v-else>Generating...</span>
          </form-button>
        </template>

      </view-options>

    </div>

    <template #no-data-message>There is no data for the selected {{ this.noData ? "date range" : "model" }}.</template>

  </summary-wrapper>
</template>

<script>
import SummaryWrapper from "@/components/page-elements/SummaryWrapper.vue";
import ViewOptions from "@/components/page-elements/ViewOptions.vue";
import DateRange from "@/components/form-elements/DateRange.vue";
import AiModelsDropdown from "@/components/form-elements/AiModelsDropdown.vue";
import FormButton from "@/components/form-elements/FormButton.vue";
import { AiModelDisplayNames } from "@/constants";
import { mapGetters } from "vuex";

import { ROUTE } from "@/constants";

export default {
  props: ["groupedSummaries", "groupedResponses", "noData", "loadingData"],
  data() {
    return {
      route: ROUTE,
      maxSummaryHeights: null,
      reportedHeights: [],
      isGenerating: false,
    };
  },
  components: {
    SummaryWrapper,
    ViewOptions,
    DateRange,
    AiModelsDropdown,
    FormButton
  },

  computed: {
    ...mapGetters("ai", {
      aiTag: "aiTag",
      aiPromptGroup: "aiPromptGroup",
      aiPromptGroups: "aiPromptGroups",
      selectedTimeRange: "selectedTimeRange",
      aiEngineModel: "aiEngineModel",
      userInfo: "userInfo",
    }),
  },
  watch: {
    selectedTimeRange() {
      this.isGenerating = false;
    },
    aiEngineModel() {
      this.isGenerating = false;
    },
    aiPromptGroup() {
      this.isGenerating = false;
    }

  },
  methods: {
    selectedPromptGroupIsPeer() {
      return this.aiPromptGroup && typeof this.aiPromptGroup === 'object' && 'parent_prompt_group_id' in this.aiPromptGroup;
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },

    generateReport() {
      const clientid = this.userInfo?.client?.clientid;
      const aiPromptGroupId = this.aiPromptGroup.id;
      const entityName = this.aiPromptGroup.display_name;
      const aiEngineModelId = this.aiEngineModel.ai_engine_model_id;
      const modelType = this.aiEngineModel.model_type;

      // Extract start and end dates from this.selectedTimeRange
      const startDate = this.selectedTimeRange[0];
      const endDate = this.selectedTimeRange[1];

      // Format the dates to YYYY-MM-DD
      const reportStartDate = this.formatDate(startDate);
      const reportEndDate = this.formatDate(endDate);

      // Set the loading state to true
      this.isGenerating = true;

      // Now you can use these variables to generate the report
      console.log('Generating report for:');
      console.log('Client ID:', clientid);
      console.log('AI Prompt Group ID:', aiPromptGroupId);
      console.log('AI Engine Model ID:', aiEngineModelId);
      console.log('Start Date:', reportStartDate);
      console.log('End Date:', reportEndDate);

      const modelDisplayType = AiModelDisplayNames[modelType];

      this.$api.generateAIReport(clientid, aiPromptGroupId, entityName, aiEngineModelId, modelType, modelDisplayType, reportStartDate, reportEndDate, 'track_over_time_report')
        .then(response => {
          console.log('Report generate started:', response);

          const clientName = this.userInfo?.first_name + ' ' + this.userInfo?.last_name;
          const entityDisplayName = this.aiPromptGroup?.display_name;
          const message = `Your report for ${entityDisplayName} for ${clientName} is being generated.`;

          this.$notifier.info({
            message: message,
            duration: 20000
          });
        })
        .catch(error => {
          console.error('Error generating report:', error);
          this.$notifier.error({ message: 'Error generating report. Please try again.' });
        })
        .finally(() => {
          // Set the loading state to false
          this.isGenerating = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.generate-report {
  align-self: flex-end;
  font-size: .80rem;
  height: 32px;
  margin-bottom: .5rem;
  margin-left: 1.5rem;
}

.loading-indicator {
  margin-left: 1rem;
  display: flex;
  align-items: center;
}
</style>
