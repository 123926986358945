<template>
  <div class="selection-wrapper" :class="{ 'has-field-labels': fieldLabels }">
    <slot />
    <div class="d-flex flex-row">
      <div class="sentiment-check d-flex ms-1 form-switch">
        <input class="form-check-input" type="checkbox" id="sentiment-switch" v-model="showSentiment" />
        <label class="form-check-label" for="sentiment-switch">Sentiment</label>
      </div>
    </div>
    <div class="d-flex flex-row">
      <div class="tags-check d-flex ms-1 form-switch">
        <input class="form-check-input" type="checkbox" id="tags-switch" v-model="showTags" />
        <label class="form-check-label" for="tags-switch">Tags</label>
      </div>
    </div>
    <slot name="button" />
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["fieldLabels"],
  methods: {
    ...mapActions("ai", [
      "setShowSentiment",
      "setShowTags",
    ]),
  },
  computed: {
    showSentiment: {
      get() {
        return this.$store.state.ai.showSentiment;
      },
      set(value) {
        this.setShowSentiment(value);
      },
    },
    showTags: {
      get() {
        return this.$store.state.ai.showTags;
      },
      set(value) {
        this.setShowTags(value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";

.selection-wrapper {
  position: sticky;
  left: var(--gutter-width);
  max-width: max-content;
  z-index: calc(var(--z-sticky-baseline) + 2);

  display: flex;
  align-items: center;
  margin-top: 1rem;
  flex-wrap: wrap;
  gap: 1rem;

  .sentiment-check,
  .tags-check {
    gap: 0.5rem;

    .form-check-label {
      @include title-styles;
      color: var(--Primary, #545f71);
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.28px;
    }
  }
}

.has-field-labels {
  .sentiment-check,
  .tags-check {
    margin-top: 19px;
  }
}
</style>
