<template>

  <summary-wrapper :groupedSummaries="hasPeers ? groupedSummaries : null"
                   :groupedResponses="hasPeers ? groupedResponsesForSummaries : null"
                   :routeback="route.peersCompare"
                   :loading="loadingData">

    <div class="options-wrapper">

      <view-options :field-labels="true">
        <date-selector />
        <ai-models-dropdown />
      </view-options>
    </div>

    <template #no-data-message>
      <span v-if="hasPeers">
        There is no peer data for the selected {{ this.noData ? "date range" : "model" }}.
      </span>
      <span v-else class="no-data-link">
        There are no peers for this topic. To add peers return to the <a class="manage-page-link" @click="routeTo('/promptGroups')">manage page</a>.
      </span>
    </template>

  </summary-wrapper>
</template>

<script>
import { mapGetters } from "vuex";
import SummaryWrapper from "@/components/page-elements/SummaryWrapper.vue";
import ViewOptions from "@/components/page-elements/ViewOptions.vue";
import DateSelector from "@/components/form-elements/DateSelector.vue";
import AiModelsDropdown from "@/components/form-elements/AiModelsDropdown.vue";
import { ROUTE } from "@/constants";
import useRouteHelper from "@/composables/useRouteHelper";

export default {
  props: ["groupedSummaries", "groupedResponses", "noData", "loadingData"],
  data() {
    return {
      route: ROUTE,
    };
  },
  components: {
    SummaryWrapper,
    ViewOptions,
    DateSelector,
    AiModelsDropdown,
  },
  methods: {
  },
  computed: {
    ...mapGetters("ai", {
      aiPromptGroup: "aiPromptGroup",
    }),
    hasPeers() {
      return this.aiPromptGroup?.peers?.length;
    },
    groupedResponsesForSummaries() {
      return this.groupedSummaries.map(summaryRecord => {
        return this.groupedResponses.filter(group => group.responses[0].ai_prompt_group_id === summaryRecord.summaries[0].ai_prompt_group_id)[0];
      });
    },
  },
  setup() {
    const { routeTo } = useRouteHelper();
    return {
      routeTo,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";

.options-wrapper {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.no-data-link {
  cursor: pointer;
}
</style>
