<template>
  <view-wrapper>
    <div class="top-section">
      <div class="d-flex flex-column header-section">
        <div class="prompt-group-dropdown">
          <div class="dropdown d-flex align-items-center">
            <span class="button-text">Compare Peers to</span>
            <client-dropdown :shouldShowPeers="false"/>
          </div>
        </div>
      </div>
    </div>

    <div class="multiselect-container">
      <label for="aiPeers">Select Peers/Competitors</label>
      <MultiSelect :options="peers"
                   valueProp="ai_peer_prompt_group_id"
                   labelProp="ai_prompt_group"
                   noData="No Peers Available"
                   :value="peers ? selectedPeers : []"
                   @input="updateSelectedPeers" />
    </div>

    <Summary :grouped-summaries="groupedSummaries"
             :grouped-responses="groupedResponses"
             :no-data="noSummaryData"
             :loading-data="loadingData" />
  </view-wrapper>
</template>


<script>
import { mapActions, mapGetters } from "vuex";

import ViewWrapper from "@/views/ViewWrapper.vue";
import { ROUTE } from "@/constants";
import Summary from "@/components/peer-compare/Summary.vue";
import ClientDropdown from "@/components/form-elements/ClientDropdown.vue";
import MultiSelect from "@/components/form-elements/MultiSelect.vue";
import { _ } from "vue-underscore";

export default {
  components: {
    ViewWrapper,
    Summary,
    ClientDropdown,
    MultiSelect,
  },

  data() {
    return {
      dateOptions: [],
      summaries: [],
      responses: [],
      route: ROUTE,
      prompts: [],
      selectedPeers: [],
      noSummaryData: false,
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters("ai", {
      aiPromptGroup: "aiPromptGroup",
      aiPromptGroups: "aiPromptGroups",
      selectedDate: "selectedDate",
      aiEngineModel: "aiEngineModel",
    }),
    peers() {
      const peers = this.aiPromptGroup?.peers || [];
      return [...peers];
    },
    filteredSummaries() {
      // Filter by model_type
      return this.summaries.filter((summary) => {
        return summary.model_type === this.aiEngineModel.model_type;
      });
    },

    groupedSummaries() {
      const summaries = this.filteredSummaries;
      const grouped = [];

      // Iterate through sorted summaries and group them by date
      summaries.forEach((summary) => {
        const date = this.$dateTime.fromISO(summary.datetime).toFormat("MM/dd/yyyy");
        const key = date + "-" + summary.model_type + "-" + summary.ai_prompt_group_id;

        // Check if there is already a group with the same key
        const existingGroup = grouped.find((group) => group.key === key);

        if (existingGroup) {
          // Add the summary to the existing group
          existingGroup.summaries.push(summary);
        } else {
          // Create a new group and add the summary
          const newGroup = {
            key: key,
            summaries: [summary],
          };
          grouped.push(newGroup);
        }
      });

      // Sort the grouped array based on the keys in ascending order
      grouped.sort((a, b) => b.key.localeCompare(a.key));

      return grouped;
    },

    filteredResponses() {
      // Filter by model_type
      return this.responses.filter((response) => {
        return response.model_type === this.aiEngineModel.model_type;
      });
    },
    sortedFilteredResponses() {
      // Create a copy of filteredSummaries to avoid mutating the original array
      const responseCopy = [...this.filteredResponses];

      // Sort the array by datetime in descending order (latest first)
      responseCopy.sort((a, b) => {
        const dateA = new Date(a.datetime);
        const dateB = new Date(b.datetime);

        return dateB - dateA;
      });

      return responseCopy;
    },
    groupedResponses() {
      const responses = this.sortedFilteredResponses;
      const grouped = [];

      // Iterate through sorted responses and group them by date
      responses.forEach((response) => {
        const date = this.$dateTime.fromISO(response.datetime).toFormat("MM/dd/yyyy");
        const key = date + "-" + response.model_type + "-" + response.ai_prompt_group_id;

        // Find the index of the group in the array
        const index = grouped.findIndex((group) => group.key === key);

        if (index === -1) {
          // If the group doesn't exist, create a new one
          grouped.push({ key, responses: [response] });
        } else {
          // If the group already exists, add the response to the existing group
          grouped[index].responses.push(response);
        }
      });

      // Sort the grouped array based on the keys in ascending order
      grouped.sort((a, b) => b.key.localeCompare(a.key));

      return grouped;
    },
    uniqueAiPrompts() {
      const responses = this.groupedResponses;
      const aiPrompts = _.chain(responses)
        .map((group) => group.responses) // Extract 'responses' array from each group
        .flatten() // Flatten the array of arrays
        .pluck("ai_prompt") // Extract 'ai_prompt' property from each object
        .compact() // Remove null or undefined values
        .uniq() // Get unique values
        .value(); // Extract the final result from the chain

      return aiPrompts;
    },
    promptGroupIdList() {
      return this.aiPromptGroup.id +
        (this.selectedPeers.length ? "," + this.selectedPeers.map(peer => peer.ai_peer_prompt_group_id).join(",") : "");
    },
  },
  methods: {
    ...mapActions("general", ["setView"]),
    ...mapActions("ai", [
      "setAiPromptGroup",
      "initializeAI",
      "setAppropriatePromptGroup",
    ]),
    async fetchData() {
      this.summaries = [];
      this.responses = [];
      this.noSummaryData = false;
      this.noResponseData = false;
      this.loadingData = true;

      try {
        const [summariesResult, responsesResult] = await Promise.all([
          this.$api.mongoGetSummariesByPromptGroupForDate(this.promptGroupIdList, this.selectedDate),
          this.$api.mongoGetResponsesByPromptGroupForDate(this.promptGroupIdList, this.selectedDate),
        ]);

        if (summariesResult && summariesResult.data) {
          const allSummaries = summariesResult.data;
          if (!allSummaries.length) {
            this.noSummaryData = true;
          }
          const selectedGroupSummaries = allSummaries.filter(
            (summary) => summary.ai_prompt_group_id === this.aiPromptGroup.id
          );
          const otherSummaries = allSummaries.filter(
            (summary) => summary.ai_prompt_group_id !== this.aiPromptGroup.id
          );
          this.summaries = [...selectedGroupSummaries, ...otherSummaries];
        } else {
          this.noSummaryData = true;
          console.error("Did not receive summaries data");
        }

        if (responsesResult && responsesResult.data) {
          const allResponses = responsesResult.data;
          if (!allResponses.length) {
            this.noResponseData = true;
          }
          const selectedGroupResponses = allResponses.filter(
            (response) => response.ai_prompt_group_id === this.aiPromptGroup.id
          );
          const otherResponses = allResponses.filter(
            (response) => response.ai_prompt_group_id !== this.aiPromptGroup.id
          );
          this.responses = [...selectedGroupResponses, ...otherResponses];
        } else {
          this.noResponseData = true;
          console.error("Did not receive responses data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        this.noSummaryData = true;
        this.noResponseData = true;
      }

      this.loadingData = false;
    },

    updateSelectedPeers(value) {
      this.selectedPeers = value;
      if (value.length) {
        this.fetchData();
      }
    },
  },
  watch: {
    async aiPromptGroup() {
      this.updateSelectedPeers(this.peers);
    },

    selectedDate(currentValue, previousValue) {
      if (previousValue) {
        this.fetchData();
      }
    },
  },
  mounted() {
    /*
     ** init ref variables
     */
    this.setView("peersCompare");
    this.initializeAI({ notifier: this.$notifier }).then(this.setAppropriatePromptGroup).then(() => {
      // if peer selected, then re-select original prompt group
      if (this.aiPromptGroup?.parent_prompt_group_id) {
        const promptGroup =  this.aiPromptGroups.find(promptGroup => promptGroup.id === this.aiPromptGroup.parent_prompt_group_id);
        this.setAiPromptGroup(promptGroup ? promptGroup : null);
      } else {
        this.updateSelectedPeers(this.peers);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";

.headers-wrapper {
  display: inline-flex;
  gap: var(--card-spacing);
}

.top-section {
  position: sticky;
  left: var(--gutter-width);
  max-width: max-content;
  z-index: calc(var(--z-sticky-baseline) + 3);

  .header-section {
    margin-bottom: var(--component-spacing-v);
  }

  .prompt-group-dropdown {
    .dropdown {
      @include title-styles;
      font-size: 32px;
      gap: 1rem;
    }

    .button-text {
      color: #545f71;
      white-space: nowrap;
    }
  }
}

.multiselect-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 65rem;

  label {
    align-self: stretch;
    color: var(--grey7);

    @include body4;
  }

  .no-peers {
    color: var(--grey7);
    @include body3;
  }
}
</style>
