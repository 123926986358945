<template>
  <div>
    <view-options>
      <date-selector :show-label="false" class="date-selector" />
    </view-options>

    <!-- lower section -->
    <response-wrapper :grouped-responses="groupedResponses" :no-data="noData" :loading-data="loadingData"
      :routeback="route.modelsCompare" />
  </div>
</template>

<script>
import ResponseWrapper from "@/components/page-elements/ResponseWrapper.vue";
import DateSelector from "@/components/form-elements/DateSelector.vue";
import ViewOptions from "@/components/page-elements/ViewOptions.vue";
import { ROUTE } from "@/constants";

export default {
  props: ["groupedResponses", "noData", "loadingData"],
  data() {
    return {
      route: ROUTE,
    };
  },
  components: {
    ResponseWrapper,
    DateSelector,
    ViewOptions,
  },

};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/icons.scss";
</style>
