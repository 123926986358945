async function downloadPDF(api, aiReport) {
  try {
    // Fetch the PDF file from the S3 bucket
    const response = await api.getS3BucketFile(aiReport.report_path_pdf);

    // Convert the response data to a Blob
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

    // Format the filename
    const date = new Date(aiReport.creation_date);
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    const year = date.getFullYear();
    const formattedDate = `${month}_${day}_${year}`;
    const filename = `${aiReport.ai_report_lookup_name.replace(/\s+/g, '_')}_${aiReport.ai_prompt_group_display_name.replace(/\s+/g, '_')}_${aiReport.model_type}_${formattedDate}.pdf`;

    // Create a link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(pdfBlob);
    link.download = filename;

    // Append the link to the body and click it to trigger the download
    document.body.appendChild(link);
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);

    console.log('PDF document download initiated');
  } catch (error) {
    console.error('Error generating PDF:', error);
    alert('Failed to generate PDF. Please try again.');
  }
}


export default {
  downloadPDF
};