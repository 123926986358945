<template>
  <div v-if="!groupedSummary">
    Data not available
  </div>
  <div v-else class="summary-card mt-3">
    <card-header :dateHeader="dateHeader" :groupedData="groupedSummary" />
    <div class="summary-content">
      <div class="summary" v-if="groupedSummary.length">

        <div class="summary-item-container" ref="section1" :style="getSectionStyle(0)"
          :class="{ 'is-especially-tall': isEspeciallyTall }">
          <div class="summary-item">
            <div class="d-flex flex-column">
              <Tags v-if="showTags" :response="groupedSummary[0]" @tagHover="handleTagHover(0, $event)" :routeback="routeback" class="mb-1" :shouldHighlightOnHover="true" />
              <span class="bullet" ref="bullet" v-html="highlightedSummary(0)" />
            </div>
          </div>
          <div class="see-more" v-if="isEspeciallyTall && !showMore" @click="showMore = true">See More</div>
        </div>

        <div class="summary-item-container" ref="section2" :style="getSectionStyle(1)">
          <div class="summary-item">
            <sources :prompts="sourceList" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardHeader from "@/components/card-elements/CardHeader.vue";
import Tags from "@/components/form-elements/tags/index.vue";
import Sources from "@/components/form-elements/Sources.vue";
import { mapGetters } from "vuex";
import aiUtil from "@/utils/aiUtil.js";

export default {
  emits: ["updateMaxSummaryHeights"],
  props: ["dateHeader", "groupedSummary", "groupedResponses", "routeback", "maxSummaryHeights",],
  data() {
    return {
      highlightedContent: "",
      aiUtil,
      reportedTopHeight: null, // to calculate isEspeciallyTall
      isEspeciallyTall: false,
      showMore: false,
      highlightTag: null,
    };
  },
  components: {
    CardHeader,
    Tags,
    Sources,
  },
  computed: {
    ...mapGetters("ai", {
      showSentiment: "showSentiment",
      showTags: "showTags",
      neutralThreshold: "neutralThreshold",
    }),
    highlightedSummary() {
      return (section) => {
        const groupedSummary = this.groupedSummary[section];
        let highlightedSummary = groupedSummary ? this.highlightMatchingPhrases(groupedSummary) : '';

        if (this.highlightTag && this.highlightTag.section === section) {
          highlightedSummary = this.formattedAndHighlightedContent(highlightedSummary, section);
        }

        return highlightedSummary;
      };
    },
    sourceList() {
      if (!this.groupedResponses) return [];

      const matchingRecord = this.groupedResponses.find(item => {
        // Extract date and model type from the item key
        const [date, modelType] = item.key.split('-');

        // Format date to match this.summary.datetime
        const formattedDate = `${date.slice(6, 10)}-${date.slice(0, 2)}-${date.slice(3, 5)}`;

        // For peers - only get sources for this entity
        const id = item.responses[0].ai_prompt_group_id;

        // Check if response matches this summary item
        return formattedDate === this.groupedSummary[0].datetime.slice(0, 10)
          && modelType === this.groupedSummary[0].model_type
          && id == this.groupedSummary[0].ai_prompt_group_id;
      });

      return matchingRecord?.responses || [];
    },

  },
  methods: {
    handleTagHover(section, tag) {
      this.highlightTag = { section, tag };
    },

    highlightMatchingTag(prompt) {
      // Copy the content to avoid modifying the original prompt
      let highlightedContent = prompt;

      if (this.highlightTag?.tag?.phrase) {
        const regex = new RegExp(aiUtil.escapeRE(this.highlightTag.tag.phrase), "gi");
        highlightedContent = highlightedContent.replace(
          regex,
          (match) => `<span class="matching-phrase">${match}</span>`
        );
      }

      return highlightedContent;
    },
    formattedAndHighlightedContent(content, section) {
      const formattedContent = aiUtil.stylizeTextBasedFormatting(content);
      if (this.highlightTag && this.highlightTag.section === section) {
        return this.highlightMatchingTag(formattedContent);
      }
      return formattedContent;
    },

    highlightMatchingPhrases(summary) {
      const content = summary?.bullet;
      const sentiments = summary?.sentiments;
      if (!content || !sentiments) return;

      let highlightedContent = aiUtil.stylizeTextBasedFormatting(content);
      if (this.showSentiment) {
        sentiments.forEach(phrase => {
          const regex = new RegExp(aiUtil.escapeRE(phrase.phrase), "gi");
          highlightedContent = highlightedContent.replace(
            regex,
            `<span class="${aiUtil.getSentiment(phrase.score, this.neutralThreshold)}-text">$&</span>`
          );
        });
      }
      return highlightedContent;
    },
    getSectionStyle(index) {
      if (this.maxSummaryHeights) {
        const height = this.maxSummaryHeights[index] + 'px';
        if (this.isEspeciallyTall && !this.showMore) {
          return { height, overflow: 'hidden' };
        } else {
          // using min-height so the boxes can expand (sources or read more)
          return { minHeight: height };
        }
      }
    },
    reportHeights() {
      const heights = ["section1", "section2", "section3"].map(section => this.$refs[section]?.clientHeight);
      this.reportedTopHeight = heights[0];
      this.$emit("updateMaxSummaryHeights", heights);
    },
  },
  mounted() {
    this.reportHeights();
  },
  watch: {
    /**
     * This watcher works together with the watcher in the summary-wrapper (parent) component.
     * The cards need to know how tall the sections should be so they all align. When the data
     * changes, the parent resets maxSummaryHeights. That triggers each child to report its
     * height to the parent. Once the parent gathers all of the heights it sets maxSummaryHeights 
     * with the maxs, which tell this component how tall to make the sections.
     */
    maxSummaryHeights(value) {
      if (value) { // after reporting parent set it
        this.isEspeciallyTall = this.reportedTopHeight - this.maxSummaryHeights[0] > 5; // allow for slight differences due to margin etc
        this.showMore = false;
      }
      else { // parent is waiting for us to report
        window.setTimeout(this.reportHeights, 0);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/_icons.scss";
@import "@/scss/_mixins.scss";

.summary-card {
  display: flex;
  flex-direction: column;
  height: 100%;

  .summary-content {
    flex-grow: 1;
  }

  .summary {
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 20px;
    background: var(--grey1);

    &.center {
      align-items: center;
    }
  }

  .summary-item-container {
    width: 100%;
    padding: 12px 10px 12px 16px;
    border-bottom: 1px solid var(--grey3);
    background: var(--white);
    margin-top: .5rem;

    &.hide {
      // hide in this way so it still has a height when we measure for aligning the boxes vertically
      z-index: -10000;
      margin-top: -10000px;
    }

    :deep(.negative-text) {
      background-color: #ffa5a5;
    }

    &:last-child {
      border-bottom: none;
    }

    .summary-item {
      @include title-styles;
      align-self: stretch;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
      text-overflow: ellipsis;
      overflow: hidden;

      .summary-item-title {
        color: var(--grey7);
        margin-bottom: 1rem;
        @include h4-style;

      }

      .bullet {
        align-self: stretch;
        color: var(--grey8);

        @include body3;
      }

      :deep(.matching-phrase) {
        background-color: yellow !important;
        transition: background-color 0.3s ease;
      }
    }

    position: relative; // for see more

    .see-more {
      position: absolute;
      background-image: linear-gradient(to top, white, rgba(255, 255, 255, .8), rgba(255, 255, 255, 0));
      padding: 2rem 0 .3rem;
      text-shadow: 1px 1px white;
      cursor: pointer;
      color: var(--primary-blue5);
      font-weight: 500;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
    }

  }

}
</style>
